import { Grid } from '@mui/material'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import { useEffect, useState } from 'react'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
// import SettlementLogs from "layouts/merchantSettlements/settlementLogs"
import SettlementLogsV2 from "layouts/merchantSettlements/settlementLogsV2"
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import { useConfig } from "../../../config"
import formatDateAndTime from "util/formatDateAndTime";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import BeatLoader from "react-spinners/BeatLoader";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import logo from "assets/images/payhub.png"
import { capitalizeFirstLetter } from 'util/formatDateAndTime'
import { useNavigate } from 'react-router-dom'
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSanbox'
import { MenuItem } from '@mui/material'
import CalculatorModal from 'layouts/calculator'
function settleMerchant() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const limit = 10
    const [page, setPage] = useState(0)
    // const [csvCompleteData, setCsvCompleteData] = useState([])
    // const [loading, setLoading] = useState(false)
    const [logsType, setLogsType] = useState("all")
    const [allDataLoading, setAllDataLoading] = useState(true)
    const validationSchema = Yup.object({
        amount: Yup.number().required('Gross Volume is required'),
        feesPercentage: Yup.number()
            .min(0, 'Fees Percentage cannot be negative')
            .max(100, 'Fees Percentage cannot exceed 100')
            .when('selectedType', {
                is: (type) =>
                    type === 'settlement' ||
                    type === 'refund' ||
                    type === 'chargeback',
                then: (schema) => schema.required('Platform Fee field is required'),
                otherwise: (schema) => schema.notRequired(),
            }),

        bankFees: Yup.number()
            .min(0, 'Bank Fees cannot be negative')
            .max(100, 'Bank Fees cannot exceed 100')
            .when('selectedType', {
                is: 'settlement',
                then: (schema) => schema.required('Bank Fees field is required'),
                otherwise: (schema) => schema.notRequired(),
            }),
        usdtRate: Yup.number(),
        gst: Yup.number()
            .min(0, 'GST cannot be negative')
            .max(100, 'GST cannot exceed 100')
            .when('selectedType', {
                is: 'settlement',
                then: (schema) => schema.required('GST field is required'),
                otherwise: (schema) => schema.notRequired(),
            }),
        ref_no: Yup.string(),
    });
    // const validationSchemaDateRange = Yup.object().shape({
    //     start_date: Yup.date()
    //         .required('Start date is required')
    //         .typeError('Invalid date'),
    //     end_date: Yup.date()
    //         .required('End date is required')
    //         .typeError('Invalid date')
    //         .min(
    //             Yup.ref('startDate'),
    //             'End date cannot be earlier than start date'
    //         ),
    // });
    const [settlementLogs, setSettlementLogs] = useState([])
    const [controller] = useMaterialUIController()
    const {
        darkMode
    } = controller
    const [data, setData] = useState()
    const [totalData, setTotalData] = useState()
    const [settlementType, setSettlementType] = useState([])
    const [selectedType, setSelectedType] = useState('');
    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: state || '',
        settlementType: 'settlement',
        amount: 0,
        feesPercentage: 0,
        bankFees: 0,
        usdtRate: 0,
        gst: 0,
        ref_no: '',
        // notes: ''
    }
    const initialDateRangeValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: state || '',
        start_date: '',
        end_date: ''
    }
    const [netVolume, setNetVolume] = useState(0)
    const [netFees, setNetFees] = useState(0)
    const [netBankFees, setNetBankFees] = useState(0)
    const [usdtNet, setUsdtNet] = useState(0)
    const [netGst, setNetGst] = useState(0)
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => {
        setNetFees(0)
        setNetBankFees(0)
        setNetGst(0)
        setNetVolume(0)
        setUsdtNet(0)
        setSelectedType('settlement')
        setOpenDialog(false);

    };
    const createPDF = async (log) => {
        const pdfDoc = await PDFDocument.create();

        // Add a new page with full A4 dimensions
        const page = pdfDoc.addPage([595, 842]);

        // Load fonts
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        // Embed logo image
        const logoUrl = logo; // Assuming `logo` is defined elsewhere with the actual URL or path to the logo
        const logoImageBytes = await fetch(logoUrl).then((res) => res.arrayBuffer());
        const logoImage = await pdfDoc.embedPng(logoImageBytes);

        // Draw the Payhub logo at the top
        page.drawImage(logoImage, {
            x: 50,
            y: 760,
            width: 140,
            height: 30,
        });

        // Header - New title for the report
        page.drawText(`Transaction Settlement Report`, {
            x: 50,
            y: 720,
            size: 32,
            font: boldFont,
            color: rgb(0, 0, 0),
        });

        // Draw the separator line below the title
        page.drawLine({
            start: { x: 50, y: 710 },
            end: { x: 545, y: 710 },
            thickness: 2,
            color: rgb(0.1, 0.2, 0.7),
        });

        // Add spacing before the intro text
        let yPosition = 690;

        // Greeting and introduction text
        page.drawText('Hello from Payhub!', { x: 50, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        yPosition -= 15;
        page.drawText('Your transaction report is ready!', { x: 50, y: yPosition, size: 12, font: regularFont });
        yPosition -= 15;
        page.drawText('The details of your transaction are enclosed for your reference.', { x: 50, y: yPosition, size: 12, font: regularFont });

        // Add spacing before Merchant, Settlement Date, Type of Report, and Settlement No.
        yPosition -= 30;
        page.drawText(`Merchant - ${capitalizeFirstLetter(data?.business_name)}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
            color: rgb(0, 0, 0),
        });
        yPosition -= 15;
        page.drawText(`Settlement Date - ${log.transaction_date.split('T')[0]}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
        });
        yPosition -= 15;
        page.drawText(`Type of Report - ${log?.type === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(log?.type) || 'N/A'}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
            color: rgb(0, 0, 0),
        });
        yPosition -= 15;
        page.drawText(`Settlement No. - ${(log.txIndex + 1)}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
        });

        // Add spacing before the table headers
        yPosition -= 30;

        // Table header
        const currencyColumnX = 300; // Shifted to the right
        const rateColumnRightBoundary = 430; // Shifted to the right
        const amountColumnRightBoundary = 545;

        page.drawText('Particulars', { x: 50, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Currency', { x: currencyColumnX, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Rate (%)', { x: rateColumnRightBoundary - 40, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Amount', { x: amountColumnRightBoundary - 50, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });

        // Draw line below header
        yPosition -= 5;
        page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: 545, y: yPosition },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        yPosition -= 15;

        // Table data
        const tableData = [
            { particulars: 'Total Amount', currency: 'INR', amount: log.amount.toFixed(2).toString(), rate: '' },
            { particulars: 'Platform Fee', currency: 'INR', amount: log?.netFees?.toFixed(2).toString() || '0.00', rate: log?.feePercentage?.toString() || '' },
            { particulars: 'Bank Fee', currency: 'INR', amount: log?.netBankFees?.toFixed(2).toString() || '0.00', rate: log?.bankFees?.toString() || '' },
            { particulars: 'GST', currency: 'INR', amount: log?.netGst?.toFixed(2).toString() || '0.00', rate: log?.gst?.toString() || '' },
            { separator: true }, // Always include the separator before Net Amount
            { particulars: 'Net Amount', currency: 'INR', amount: log?.netVolume?.toFixed(2).toString() || '0.00', rate: '' },
        ];

        // Filter rows based on type
        const filteredTableData = tableData.filter((row, index) => {
            if (row.separator || row.particulars === 'Net Amount') return true; // Always include separator and Net Amount
            if (log.type === 'settlement') return index <= 3; // Show Total Amount, Platform Fee, Bank Fees, and GST
            if (['refund', 'chargeback'].includes(log.type)) return index <= 1; // Show Total Amount, Platform Fee
            if (['misc', 'rolling_reserve'].includes(log.type)) return index === 0; // Show only Total Amount
            return false; // Exclude other rows
        });

        // Render table rows
        filteredTableData.forEach((item) => {
            if (item.separator) {
                yPosition -= 10;
                page.drawLine({
                    start: { x: 50, y: yPosition },
                    end: { x: 545, y: yPosition },
                    thickness: 1,
                    color: rgb(0.1, 0.2, 0.7),
                });
                yPosition -= 20;
                return;
            }

            // Align Particulars to the left
            page.drawText(item.particulars, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            // Align other columns to the right
            page.drawText(item.currency, { x: currencyColumnX, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            const rateTextWidth = regularFont.widthOfTextAtSize(item.rate, 12);
            page.drawText(item.rate, {
                x: rateColumnRightBoundary - rateTextWidth,
                y: yPosition,
                size: 12,
                font: regularFont,
            });

            const amountTextWidth = regularFont.widthOfTextAtSize(item.amount, 12);
            page.drawText(item.amount, {
                x: amountColumnRightBoundary - amountTextWidth,
                y: yPosition,
                size: 12,
                font: regularFont,
            });

            yPosition -= 20;
        });

        // USDT Conversion Summary (conditionally displayed)
        if (log?.type === 'settlement') {
            yPosition -= 30;
            page.drawText('USDT Conversion Summary', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
            yPosition -= 20;
            page.drawText(`USDT Rate: ${log?.usdtRate?.toString()} INR`, { x: 50, y: yPosition, size: 12, font: regularFont });
            yPosition -= 20;
            page.drawText(`Equivalent in USDT: ${log?.usdt?.toFixed(2) || 'N/A'}`, { x: 50, y: yPosition, size: 12, font: regularFont });
        }

        // Remarks Section
        yPosition -= 30;
        page.drawText('Remarks', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
        yPosition -= 20;
        page.drawText(log?.ref_no || 'No remarks provided.', { x: 50, y: yPosition, size: 12, font: regularFont });

        // Footer Section
        yPosition -= 30;
        page.drawLine({
            start: { x: 50, y: yPosition + 10 },
            end: { x: 545, y: yPosition + 10 },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        yPosition -= 20;
        page.drawText('Got questions? FAQ or get in touch with one of our experts here.', { x: 50, y: yPosition, size: 10, font: regularFont, color: rgb(0, 0, 1) });
        yPosition -= 15;
        page.drawText('Thank you!\nTeam Payhub', { x: 50, y: yPosition - 20, size: 10, font: regularFont, color: rgb(0, 0, 0) });

        // Save and download the PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatDateAndTime(log.transaction_date).formattedDate}.pdf`;
        link.click();
    };

    const getMerchantData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || ''
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantdata`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getSettlementTypes = async () => {
        try {
            const response = await fetch(`${apiUrl}/admin/settlementConstants`, {
                method: 'GET',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementType(res?.responseData)
            setSelectedType(res?.responseData[0])
        } catch (err) {
            console.log("Error Fetching getSettlementTypes: ", err)
        }
    }
    const getMerchantTotalData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || ''
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/gettotalvolumeandsettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotalData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getSettlementLogs = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || '',
                type: logsType,
                limit,
                skip: page * limit,
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getMerchantSettlementByType`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // const getSettlementLogsForCsv = async (body) => {
    //     try {
    //         if (body.emailId === '' || body.apiKey === '') {
    //             return;
    //         }
    //         const response = await fetch(`${apiUrl}/admin/getmerchantsettlements`, {
    //             method: 'POST',
    //             headers: {
    //                 //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(body)
    //         })

    //         if (!response) return;
    //         const res = await response.json()
    //         if (res.responseCode !== 200) return;
    //         return res?.responseData
    //     } catch (err) {
    //         console.log("Error Fetching Transactions: ", err)
    //     }
    // }

    const handleSettle = async (values, { setSubmitting }) => {
        try {

            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            if (selectedType === "settlement") {
                values.netFees = netFees;
                values.netBankFees = netBankFees
                values.netGst = netGst
                values.netVolume = netVolume;
                values.netUSDT = usdtNet;
            } else if (selectedType === "refund" || selectedType === "chargeback") {
                values.netFees = netFees;
                values.netVolume = netVolume;
                delete values.bankFees;
                delete values.gst;
                delete values.usdtRate;
                delete values?.netGst
                delete values?.netUSDT
                delete values?.netBankFees

            } else if (selectedType === "rolling_reserve" || selectedType === "misc") {
                delete values.feesPercentage;
                delete values.bankFees;
                delete values.gst;
                delete values.usdtRate;
                delete values?.netFees;
                delete values?.netGst
                delete values?.netUSDT
                delete values?.netBankFees
                delete values?.netVolume;
            } else {
                toast.error('Invalid Settlement Type', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                return;
            }
            const response = await fetch(`${apiUrl}/admin/newSettleMoney`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            page > 0 ? (setPage(0), getMerchantTotalData(), getMerchantData()) : (getSettlementLogs(), getMerchantTotalData(), getMerchantData())
            handleCloseDialog()
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };

    // const downloadSettlementLogs = async () => {
    //     setLoading(true)
    //     setCsvCompleteData([])
    //     let csvPage = 0
    //     let responseData = true
    //     do {
    //         let body = {
    //             emailId: adminEmail || '',
    //             apiKey: adminApiKey || '',
    //             email_Id: state || '',
    //             limit: 500,
    //             skip: csvPage * 500,
    //         }

    //         await getSettlementLogsForCsv(body).then((res) => {
    //             if (res.length === 0) responseData = false;
    //             csvCompleteData.push(...res)
    //         })
    //         csvPage++
    //     } while (responseData)

    //     // Create the CSV content
    //     const csvContent = [
    //         ["Time", "Date", "Gross Volume", "Net Fees", "Bank Fees", "Net Gst", "Chargeback", "Rolling Reserve", "Misc", "Refund", "Net Volume", "USDT Rate", "USDT Net", "Ref"],
    //         ...csvCompleteData.map(({ transaction_date, amount, feeCharged, netBankFees, netGst, chargeback, rolling_reserve, misc, refund, amountSettled, usdtRate, usdt, ref_no }) => [
    //             formatDateAndTime(transaction_date).formattedTime || '',
    //             formatDateAndTime(transaction_date).formattedDate || '',
    //             amount,
    //             feeCharged?.toFixed(2),
    //             netBankFees?.toFixed(2),
    //             netGst?.toFixed(2),
    //             chargeback,
    //             rolling_reserve,
    //             misc,
    //             refund,
    //             amountSettled?.toFixed(2),
    //             usdtRate,
    //             usdt?.toFixed(2),
    //             ref_no || '',
    //             // notes || ''
    //         ]),
    //     ].map(row => row.join(',')).join('\n');

    //     // Create a Blob containing the CSV data
    //     const blob = new Blob([csvContent], { type: 'text/csv' });

    //     // Create a download link
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'settlement.csv';

    //     // Programmatically click the link to trigger the download
    //     a.click();

    //     // Clean up
    //     URL.revokeObjectURL(url);
    //     setLoading(false)
    // }

    const calculateNetVolume = (grossVolume, fee, usdtRates, bankfee, gstPercentage) => {
        const fees = (fee / 100) * grossVolume; // Calculating fees based on percentage
        let net;
        net = grossVolume - fees; // Calculating net volume
        const bankfees = (bankfee / 100) * net; // Calculating fees based on percentage
        net = net - bankfees
        const gstfees = (gstPercentage / 100) * fees
        net = net - gstfees
        setNetBankFees(bankfees)
        setNetFees(fees)
        setNetVolume(net);
        setNetGst(gstfees)
        calculateNetUSDT(usdtRates, net)
    }
    const calculateNetUSDT = (rate, netVolume) => {
        const net = (netVolume !== undefined && netVolume !== '') ? parseFloat(netVolume) : 0;
        const usdtRate = (rate !== undefined && rate !== '') ? parseFloat(rate) : 0;

        if (usdtRate === 0) {
            // Handle division by zero case
            setUsdtNet(0);
        } else {
            const netUSDT = net / usdtRate; // Calculating net USDT based on rate
            setUsdtNet(netUSDT);
        }
    }

    const handleTypeDateRangeSubmit = async (values, { setSubmitting }) => {
        try {

            if (values.start_date !== "" && values.end_date !== "" && new Date(values.end_date) < new Date(values.start_date)) {
                toast.error("Invalid Date Range", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 2 seconds
                    hideProgressBar: false,
                });
                return;
            }
            values.type = logsType;
            const response = await fetch(`${apiUrl}/admin/downloadSettlementCsvByDateAndType`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            const a = document.createElement('a');
            a.href = res?.responseData;
            a.download = 'settlement.csv';
            a.click(); // Programmatically click the link to trigger the download
            URL.revokeObjectURL(res?.responseData);  // Clean up
        } catch (err) {
            console.log("Error Fetching handleTypeDateRangeSubmit: ", err)
        }
    }


    useEffect(() => {
        setAllDataLoading(true)
        getMerchantData()
        getSettlementTypes()
        getMerchantTotalData().then(() => {
            setAllDataLoading(false)
        })
    }, [state, sandbox])
    useEffect(() => {
        getSettlementLogs()
    }, [state, page, sandbox, logsType])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={2} >              
                            {data && totalData && 
                            <MDBox display={'flex'} flexDirection={{xs:'column',sm:'row'}}  >                          
                                    <DefaultProjectCard
                                        business={data?.business_name}
                                        email={data?.emailId}
                                        name={`${data?.first_name} ${data?.last_name}`}
                                        btn={setOpenDialog}
                                        calculatorBtn={setIsModalOpen}
                                        type="settle"
                                    />
                                     <MDBox sx={{ overflowX: "auto", whiteSpace: "nowrap", display: "flex", gap: "16px",padding:'8px',flexDirection:{xs:'column',sm:'row'}}}>
                                        <Grid item xs={12} md={2.5} lg={2.4} style={{ flex: "0 0 auto" }} >
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="7FDF9A"
                                                    title="Today's Volume"
                                                    count={Number(data?.last24hr).toFixed(0) || ''}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item xs={12} md={2.5} lg={2.4} style={{ flex: "0 0 auto" }} >
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="FFFED2"
                                                    title="Yesterday's Volume"
                                                    count={`${Number(data?.yesterday).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} md={2.5} lg={2.4} style={{ flex: "0 0 auto" }}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="D4BEF0"
                                                    title="Total Volume"
                                                    count={`${Number(totalData?.totalVolume).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.5} xl={2.4} style={{ flex: "0 0 auto" }}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="BBF2EA"
                                                    title="Total Settlement"
                                                    count={`${Number(totalData?.totalSettlements).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.5} xl={2.4} style={{ flex: "0 0 auto" }}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="C9DEDE"
                                                    title="Wallet Balance"
                                                    count={`${Number(data.balance).toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        </MDBox>
                            </MDBox>}
                            {/* settlementLogs */}
                            <MDBox mt={5} mx={0.5} ml={3}>
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>

                                    <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py={1.5}>
                                        {/* Left section: Title and Loader/Download icon */}
                                        <Grid item xs={12} sm={5} md={5}>
                                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                                <MDTypography textTransform="uppercase" variant="h6" color={darkMode ? "white" : "black"}>
                                                    Settlement Logs
                                                </MDTypography>
                                                {/* <MDBox pl={3}>
                                                    {loading ? (
                                                        <BeatLoader color="#36d7b7" cssOverride={{}} size={10} />
                                                    ) : (
                                                        <FileDownloadOutlinedIcon
                                                            onClick={() => downloadSettlementLogs()}
                                                            sx={{ cursor: "pointer", fontWeight: "bold", color: darkMode ? "white" : "black" }}
                                                            fontSize="medium"
                                                        />
                                                    )}
                                                </MDBox> */}
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={7} xl={7} px={2}>
                                            {/* Right section: Date inputs and Download button */}
                                            <Formik initialValues={initialDateRangeValues} onSubmit={handleTypeDateRangeSubmit} >
                                                {({ handleChange, values, isSubmitting }) => (
                                                    <Form>
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Field
                                                                    type="text"
                                                                    select
                                                                    label="Select Settlement type"
                                                                    size="large"
                                                                    as={MDInput}
                                                                    fullWidth
                                                                    value={logsType}
                                                                    onChange={(e) => {
                                                                        e.target.value !== "" && setLogsType(e.target.value); setPage(0)
                                                                    }}
                                                                    InputProps={{
                                                                        classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                    }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                    <MenuItem value="all">All</MenuItem>
                                                                    {settlementType && settlementType.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(item)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                            {/* Start Date Input */}
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Field
                                                                    as={MDInput}
                                                                    label="Start Date"
                                                                    size="large"
                                                                    fullWidth
                                                                    type="date"
                                                                    name="start_date"
                                                                    onChange={handleChange}
                                                                    value={values.start_date}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>

                                                            {/* End Date Input */}
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Field
                                                                    as={MDInput}
                                                                    label="End Date"
                                                                    size="large"
                                                                    fullWidth
                                                                    type="date"
                                                                    name="end_date"
                                                                    onChange={handleChange}
                                                                    value={values.end_date}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>


                                                            {/* Download Button */}
                                                            <Grid item xs={12} sm={12} md={3} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                                                                <MDButton
                                                                    type="submit"
                                                                    variant="contained"
                                                                    fullWidth
                                                                    color={isSubmitting ? "secondary" : "info"} // Change color when submitting
                                                                    sx={{
                                                                        height: "48px",
                                                                        // minWidth: "150px",
                                                                        opacity: isSubmitting ? 0.5 : 1, // Fade button when submitting
                                                                        cursor: isSubmitting ? "not-allowed" : "pointer", // Show disabled cursor
                                                                    }}
                                                                    disabled={isSubmitting} // Disable button
                                                                >
                                                                    {isSubmitting ? "Downloading..." : "Download CSV"} {/* Change text */}
                                                                </MDButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                )}
                                            </Formik>

                                        </Grid>
                                    </Grid>

                                    {/* <SettlementLogs pages={page} pageSetter={setPage} logs={settlementLogs} downloadInvoice={createPDF} /> */}
                                    <SettlementLogsV2 pages={page} pageSetter={setPage} logs={settlementLogs} downloadInvoice={createPDF} merchant={data?.business_name} />

                                </Grid>
                            </MDBox>
                    </MDBox>
                )
            }
            <CalculatorModal
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
            />
            <Dialog PaperProps={{
                style: {
                    minHeight: '35vh',
                    minWidth: '45vw',
                    background: darkMode ? "#344767" : "white",
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', // Shadow effect
                    borderRadius: '10px', // Rounded corners
                    position: 'absolute',
                    left: '50%', // Adjust the left value to move the dialog to the right
                },
            }} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>SETTLEMENT</DialogTitle>
                <DialogContent>
                    {
                        (selectedType !== "rolling_reserve" && selectedType !== "misc") && (
                            <MDBox px={1} py={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                                    Net Fees: {netFees?.toFixed(2)}
                                </MDTypography>
                                {
                                    selectedType === "settlement" && (
                                        <>
                                            <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                                                Net Bank Fees: {netBankFees?.toFixed(2)}
                                            </MDTypography>
                                            <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                                                Net GST: {netGst?.toFixed(2)}
                                            </MDTypography>
                                        </>
                                    )
                                }

                                <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                                    Net Volume: {netVolume?.toFixed(2)}
                                </MDTypography>
                                {
                                    selectedType === "settlement" && (
                                        <MDTypography px={2} color="text" fontSize="large" fontWeight="medium" variant="caption" >
                                            Usdt Net: {usdtNet?.toFixed(2)}
                                        </MDTypography>
                                    )
                                }

                            </MDBox>
                        )
                    }

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSettle}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field
                                        type="text"
                                        select
                                        label="Settlement Type"
                                        size="large"
                                        as={MDInput}
                                        fullWidth
                                        name="settlementType"
                                        InputProps={{
                                            classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                        }}
                                        onChange={(e) => {
                                            setFieldValue("settlementType", e.target.value)
                                            setSelectedType(e.target.value)
                                        }}
                                    >
                                        {/* <MenuItem value="">Settlement Type</MenuItem> */}
                                        {settlementType && settlementType?.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {
                                                    item === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(item)
                                                }
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="settlementType" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="number" label="Gross Volume" as={MDInput} fullWidth name="amount"
                                        onFocus={(e) => e.target.value === "0" && setFieldValue('amount', '')}
                                        onBlur={(e) => e.target.value === "" && setFieldValue('amount', 0)}
                                        onChange={(e) => {
                                            setFieldValue('amount', e.target.value)
                                            calculateNetVolume(e.target.value, values.feesPercentage, values.usdtRate, values.bankFees, values.gst)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="amount" component="div" />
                                    </MDTypography>
                                </MDBox>
                                {/* platform fees and bank fees */}
                                <Grid container spacing={2} mb={2}>
                                    {
                                        (selectedType === "settlement" || selectedType === "refund" || selectedType === "chargeback") && (
                                            <Grid item xs={selectedType === "settlement" ? 6 : 12}>
                                                <Field type="number" label="Platform Fees %" as={MDInput} fullWidth name="feesPercentage"
                                                    onFocus={(e) => e.target.value === "0" && setFieldValue('feesPercentage', '')}
                                                    onBlur={(e) => e.target.value === "" && setFieldValue('feesPercentage', 0)}
                                                    onChange={(e) => {
                                                        setFieldValue('feesPercentage', e.target.value)
                                                        calculateNetVolume(values.amount, e.target.value, values.usdtRate, values.bankFees, values.gst)
                                                    }}
                                                />
                                                <MDTypography color="error" variant="caption">
                                                    <ErrorMessage name="feesPercentage" component="div" />
                                                </MDTypography>
                                            </Grid>
                                        )
                                    }

                                    {
                                        selectedType === "settlement" && (
                                            <Grid item xs={6}>
                                                <Field type="number" label="Bank Fees %" as={MDInput} fullWidth name="bankFees"
                                                    onFocus={(e) => e.target.value === "0" && setFieldValue('bankFees', '')}
                                                    onBlur={(e) => e.target.value === "" && setFieldValue('bankFees', 0)}
                                                    onChange={(e) => {
                                                        setFieldValue('bankFees', e.target.value)
                                                        calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, e.target.value, values.gst)
                                                    }}
                                                />
                                                <MDTypography color="error" variant="caption">
                                                    <ErrorMessage name="bankFees" component="div" />
                                                </MDTypography>
                                            </Grid>
                                        )
                                    }

                                </Grid>

                                {/* gst % */}
                                {
                                    selectedType === "settlement" && (
                                        <Grid container spacing={2} mb={2}>
                                            <Grid item xs={12}>
                                                <Field type="number" label="Gst %" as={MDInput} fullWidth name="gst"
                                                    onFocus={(e) => e.target.value === "0" && setFieldValue('gst', '')}
                                                    onBlur={(e) => e.target.value === "" && setFieldValue('gst', 0)}
                                                    onChange={(e) => {
                                                        setFieldValue('gst', e.target.value)
                                                        calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, values.bankFees, e.target.value)
                                                    }}
                                                />
                                                <MDTypography color="error" variant="caption">
                                                    <ErrorMessage name="gst" component="div" />
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                {/* usdt rate */}
                                {
                                    selectedType === "settlement" && (
                                        <Grid container spacing={2} mb={2}>
                                            <Grid item xs={12}>
                                                <Field type="number" label="USDT Rate" as={MDInput} fullWidth name="usdtRate"
                                                    onFocus={(e) => e.target.value === "0" && setFieldValue('usdtRate', '')}
                                                    onBlur={(e) => e.target.value === "" && setFieldValue('usdtRate', 0)}
                                                    onChange={(e) => {
                                                        setFieldValue('usdtRate', e.target.value)
                                                        calculateNetUSDT(e.target.value, netVolume)
                                                    }}
                                                />
                                                <MDTypography color="error" variant="caption" >
                                                    <ErrorMessage name="usdtRate" component="div" />
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                <MDBox mb={2}>
                                    <Field type="text" label="Remarks" as={MDInput} fullWidth name="ref_no" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="ref_no" component="div" />
                                    </MDTypography>
                                </MDBox>
                                {/* <MDBox mb={2}>
                                    <Field type="text" label="Notes" as={MDInput} fullWidth name="notes" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="notes" component="div" />
                                    </MDTypography>
                                </MDBox> */}
                                <MDBox display={'flex'} flexDirection={'row'}>
                                    <MDBox>
                                        <MDButton
                                            type="submit" color="success" disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Settling...' : 'Settle Merchant'}
                                        </MDButton>
                                    </MDBox>
                                    <MDBox ml={3}>
                                        <MDButton
                                            onClick={handleCloseDialog} color="error"
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>

            </Dialog>
        </DashboardLayout>
    )
}

export default settleMerchant