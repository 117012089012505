import React from "react";
import { useSnackBar } from "ZustandState/useSnackBar";
import MDSnackbar from "components/MDSnackbar";
import { Link } from "react-router-dom";
const GlobalSnackbar = () => {
    const successSB = useSnackBar((state) => state.successSB);
    const warningSB = useSnackBar((state) => state.warningSB);
    const closeSuccessSB = useSnackBar((state) => state.closeSuccessSB);
    const closeWarningSB = useSnackBar((state) => state.closeWarningSB);
    const contentProgress = (
        <React.Fragment>
            Generating your CSV report. Thank you for your patience.
            <Link

                to="/csv-report"
                style={{
                    marginLeft: "5px",
                    color: "white",
                    textDecoration: "none",
                }}
            >
                View
            </Link>
        </React.Fragment>
    );
    const contentSuccess = (
        <React.Fragment>
            Your CSV report is ready to download.
            <Link

                to="/csv-report"
                style={{
                    marginLeft: "5px",
                    color: "white",
                    textDecoration: "none",
                }}
            >
                View
            </Link>
        </React.Fragment>
    );
    return (
        <>
            {successSB && (
                <MDSnackbar
                    color="success"
                    icon="check"
                    title="Exported Data Report"
                    content={contentSuccess}
                    dateTime="Just now"
                    open={successSB}
                    onClose={closeSuccessSB}
                    close={closeSuccessSB}
                    bgWhite={false}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}

                />
            )}
            {warningSB && (
                <MDSnackbar
                    color="warning"
                    icon="access_time"
                    title="Exported Data Report"
                    content={contentProgress}
                    dateTime="Just now"
                    open={warningSB}
                    onClose={closeWarningSB}
                    close={closeWarningSB}
                    bgWhite={false}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}

                />
            )}
        </>
    );
};

export default GlobalSnackbar;
