import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MDTypography from "components/MDTypography";
import formatDateAndTime from "util/formatDateAndTime";
import { useMaterialUIController } from "context";
import { capitalizeFirstLetter } from "util/formatDateAndTime";

export default function data(logs, downloadInvoice, openDrawer) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return {
        columns: [
            { Header: "No.", accessor: "no", width: "4%", align: "left" },
            { Header: "Time", accessor: "time", width: "8%", align: "left" },
            { Header: "Date", accessor: "date", width: "8%", align: "left" },
            { Header: "Type", accessor: "type", width: "6%", align: "left" },
            { Header: "Gross Volume", accessor: "grossVolume", width: "10%", align: "right" },
            { Header: "Net Volume", accessor: "netVolume", width: "10%", align: "right" },
            { Header: "Balance", accessor: "balance", width: "10%", align: "right" },
            { Header: "Action", accessor: "action", width: "12%", align: "right" },
        ],

        rows: logs.map((row) => ({
            no: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.txIndex + 1}
                </MDTypography>
            ),
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(row?.transaction_date).formattedTime || ""}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(row?.transaction_date).formattedDate || ""}
                </MDTypography>
            ),
            type: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.type === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(row?.type) || ""}
                </MDTypography>
            ),
            grossVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.amount?.toFixed(0) || ""}
                </MDTypography>
            ),
            netVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.netVolume?.toFixed(0) || 0}
                </MDTypography>
            ),
            balance: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.balance?.toFixed(0) || 0}
                </MDTypography>
            ),
            action: (
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                    <Tooltip title="View Summary" arrow>
                        <InfoOutlinedIcon
                            onClick={() => openDrawer(row)}
                            sx={{ cursor: "pointer" }}
                            fontSize="medium"
                        />
                    </Tooltip>
                    <Tooltip title="Download Invoice" arrow>
                        <FileDownloadOutlinedIcon
                            onClick={() => downloadInvoice(row)}
                            sx={{ cursor: "pointer" }}
                            fontSize="medium"
                        />
                    </Tooltip>
                </div>
            ),

        })),
    };
}
