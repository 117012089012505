
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import dataFormat from "layouts/merchantSettlements/settlementLogsV2/data";
import SettlementDrawer from "layouts/merchantSettlements/settlementLogsV2/settlementDrawer"
import { useState } from 'react';
function SettlementLogsV2(props) {
    const { pages, pageSetter, logs, downloadInvoice, merchant } = props
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedSettlement, setSelectedSettlement] = useState(null);

    // Function to handle opening the drawer
    const openDrawer = (settlement) => {
        setSelectedSettlement(settlement);
        setDrawerOpen(true);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
        setSelectedSettlement(null);
    };
    const { columns, rows } = dataFormat(logs, downloadInvoice, openDrawer);
    return (
        <>
            <DataTable
                table={{ columns, rows }}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
                pages={pages}
                pageSetter={pageSetter}
            />
            {/* Settlement Drawer */}
            <SettlementDrawer
                open={drawerOpen}
                onClose={closeDrawer}
                merchant={merchant}
                settlement={selectedSettlement}
                downloadInvoice={downloadInvoice}
            />

        </>

    );
}

SettlementLogsV2.propTypes = {
    merchant: PropTypes.string,
    pages: PropTypes.number,
    pageSetter: PropTypes.func,
    logs: PropTypes.any,
    downloadInvoice: PropTypes.func
};

export default SettlementLogsV2;
