
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import { Grid } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
// import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
import { useUser } from 'ZustandState/useUser';
import { useConfig } from "../../config";
import GatewayInformation from "layouts/allGateway/gateway-info";
import Loader from "util/Loader";
import { useSandbox } from "ZustandState/useSanbox";
function gateways() {
    const [gateways, setGateways] = useState([])
    const sandbox = useSandbox((state) => state.sandbox)
    const [loading, setLoading] = useState(true)
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const { apiUrl } = useConfig()
    const user = useUser((state) => state.user)
    async function getAllGateways(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getgatewayinfo`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            setGateways(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Merchants: ", err)
        }
    }
    useEffect(() => {
        setLoading(true)
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || ''
        }
        getAllGateways(body).then(() => {
            setLoading(false)
        })
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox>
                <Card>
                    <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={5} p={2} mb={1} textAlign="center" boxShadow="md">
                        <MDTypography variant="h4" fontWeight="medium" color="white" sx={{ fontSize: { xs: "1rem", sm: "1.5rem" }}}>
                            Gateways
                        </MDTypography>
                    </MDBox>
                    { loading && (<Loader />)}
                    {!loading && 
                     <MDBox>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <GatewayInformation allGateways={gateways} getAllGatewaysfunc={getAllGateways} />
                            </Grid>
                        </Grid>
                    </MDBox>
                    }
                </Card>
            </MDBox>
        </DashboardLayout>

    )
}

export default gateways