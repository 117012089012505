/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import { CSVDownload } from "react-csv";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useState } from "react";
import { useConfig } from "../../config"
import { useEffect } from "react";
import { useSandbox } from "ZustandState/useSanbox";
import GateWayStats from "layouts/stats/gatewayStats"
import MerchantStats from "layouts/stats/merchantStats"
import MDTypography from "components/MDTypography";
import Loader from "util/Loader";
// import IconButton from '@mui/material/IconButton';
// import GetAppIcon from '@mui/icons-material/GetApp'
import { makeStyles } from '@mui/styles';
// import DownloadSVG from "assets/images/download.svg"
// import DarkSVG from "assets/images/downloadDark.svg"
import { useMaterialUIController } from "context";
const useStyles = makeStyles((theme) => ({
    paddingRight: {
        paddingRight: theme.spacing(5), // You can adjust the value as needed
    },
}));
function Stats() {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const sandbox = useSandbox((state) => state.sandbox)
    const classes = useStyles();
    const { apiUrl } = useConfig()
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const [cardsData, setCardsData] = useState({
        todayVolume: 0,
        successfulTransaction: 0,
        successRate: 0,
        yesterdayVolume: 0,
        wallet: 0
    })
    const [gatewayOverview, setGatewayOverview] = useState([])
    const [merchantOverview, seMerchantOverview] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [allDataLoading, setAllDataLoading] = useState(true)
    const getMerchantOverview = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getallmerchantsstats`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            seMerchantOverview(res?.responseData)

        } catch (err) {
            console.log("Error Fetching Merchant Overview: ", err)
        }
    }

    const getGatewayOverview = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getgatewaydetails`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setGatewayOverview(res?.responseData)

        } catch (err) {
            console.log("Error Fetching Gateway Overview: ", err)
        }
    }

    async function getAdminBalance(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getadminbalance`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            return res
        } catch (err) {
            console.log("Error Fetching Merchants: ", err)
        }
    }
    async function getVolumes(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getalltxdetails`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            return res.responseData
        } catch (err) {
            console.log("Error Fetching Merchants: ", err)
        }
    }
    const fetchData = async (body) => {
        try {
            const [walletData, volumesData] = await Promise.all([
                getAdminBalance(body),
                getVolumes(body),
            ]);
            // Once all data is fetched, update state and mark loading as false
            if (walletData && volumesData) {
                setCardsData({
                    todayVolume: volumesData.todayObject.volume,
                    successfulTransaction: volumesData.last24hrSuccess,
                    successRate: volumesData.last24hrSuccessRate,
                    yesterdayVolume: volumesData.yesterdayObject.volume,
                    wallet: walletData?.responseData
                });
                setIsLoading(false)
            }
        } catch (err) {
            console.log("Error Fetching Data: ", err);
            setIsLoading(false); // Handle errors and stop loading
        }
    };
    function capitalizeFirstLetter(word) {
        // Check if the input is a valid string
        if (typeof word !== 'string' || word.length === 0) {
            return word;
        }

        // Capitalize the first letter and concatenate the rest of the word
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    const downloadGatewayCsv = () => {
        // Create the CSV content
        const csvContent = [
            ["Gateway", "Today's Volume", "Successful Transactions", "Today's Fees", "Yesterday's Volume", "Yesterday Transaction", "Yesterday's Fees", "Today's Balance"],
            ...gatewayOverview.map(({ gatewayName, last24hr, last24hrSuccess, feeCollected24hr, yesterday, yesterdayTransactions, yesterdayFee, todaysBalance }) => [
                capitalizeFirstLetter(gatewayName),
                last24hr,
                last24hrSuccess,
                feeCollected24hr,
                yesterday,
                yesterdayTransactions,
                yesterdayFee,
                todaysBalance
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'gatewayOverview.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
    }

    const downloadMerchantCsv = () => {
        // Create the CSV content
        const csvContent = [
            ["Merchant", "Today's Volume", "Transactions", "Yesterday's Volume", "Yesterday Transaction", "Today's Fee", "Yesterday's Fees", "Wallet Balance"],
            ...merchantOverview.map(({ business_name, todayVolume, todayTransactions, yesterdayTransactions, yesterdayVolume, todayFee, yesterdayFee, walletBalance }) => [
                capitalizeFirstLetter(business_name),
                todayVolume,
                todayTransactions,
                yesterdayVolume,
                yesterdayTransactions,
                todayFee,
                yesterdayFee,
                walletBalance
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'merchantOverview.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
    }

    useEffect(() => {
        const fetchDataAndSetLoading = async () => {
            setAllDataLoading(true)
            let adminBody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            };

            try {
                await Promise.all([
                    getGatewayOverview(),
                    getMerchantOverview(),
                    fetchData(adminBody),
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setAllDataLoading(false);
            }
        };
        fetchDataAndSetLoading();
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={2}>
                        {
                            !isLoading && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="7FDF9A"
                                                title="Today Volume"
                                                count={Number(cardsData?.todayVolume).toFixed(0)}
                                            />
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                title="Successful Transactions"
                                                color="D4BEF0"
                                                count={cardsData.successfulTransaction}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={1.8} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="BBF2EA"
                                                title="Success Rate"
                                                count={Number(cardsData?.successRate)?.toFixed(0) + '%'}
                                            />
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="FFFED2"
                                                title="Yesterday's Volume"
                                                count={`${Number(cardsData?.yesterdayVolume).toFixed(0)}`}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="C9DEDE"
                                                title="Wallet"
                                                count={`${Number(cardsData.wallet).toFixed(0)}`}
                                            />
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <MDBox mt={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <MDBox display={'flex'} flexDirection={'row'} justifyContent="space-between">
                                        <MDBox>
                                            <MDTypography variant="h6" color="dark">Gateway</MDTypography>
                                        </MDBox>
                                        <MDBox alignItems="flex-end" pr={3}>
                                            {/* <img
                                        src={DownloadSVG}
                                        alt="Download Icon"
                                        style={{
                                            cursor: "pointer",
                                            fontWeight: "bold",
                                            color: darkMode ? "white" : "black", // Set the color conditionally
                                            width: "2em",
                                            height: "2em",
                                            top: "calc(50% - 1em)",
                                        }}
                                        onClick={() => downloadGatewayCsv()}
                                    /> */}
                                            <FileDownloadOutlinedIcon onClick={() => downloadGatewayCsv()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />


                                        </MDBox>
                                    </MDBox>
                                    <GateWayStats gatewayDetails={gatewayOverview} func={capitalizeFirstLetter} />

                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox mt={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <MDBox display={'flex'} flexDirection={'row'} justifyContent="space-between">
                                        <MDBox>
                                            <MDTypography variant="h6" color="dark">Merchants</MDTypography>
                                        </MDBox>
                                        <MDBox alignItems="flex-end" pr={3}>
                                            {/* <img
                                        src={DownloadSVG}
                                        alt="Download Icon"
                                        style={{
                                            cursor: "pointer",
                                            fontWeight: "bold",
                                            color: darkMode ? "white" : "black", // Set the color conditionally
                                            width: "2em",
                                            height: "2em",
                                            top: "calc(50% - 1em)",
                                        }}
                                        onClick={() => downloadMerchantCsv()}
                                    /> */}
                                            <FileDownloadOutlinedIcon onClick={() => downloadMerchantCsv()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />

                                        </MDBox>
                                    </MDBox>

                                    <MerchantStats merchantDetails={merchantOverview} func={capitalizeFirstLetter} />
                                </Grid>
                            </Grid>
                        </MDBox>
                    </MDBox>
                )
            }

        </DashboardLayout>
    );
}

export default Stats;
