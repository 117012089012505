
import React, { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
// import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// Material Dashboard 2 React examples
import DataTable from "layouts/payoutDash/DataTable";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
// Data
import InputAdornment from "@mui/material/InputAdornment";
import data from "layouts/payoutDash/components/payoutTransactions/data";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useConfig } from "../../../../config"
import formatDateAndTime from "util/formatDateAndTime";
// import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import DarkSVG from "assets/images/downloadDark.svg"
import { useMaterialUIController } from "context";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { useSandbox } from "ZustandState/useSanbox";
import { hideDigits } from "util/formatDateAndTime";
import { capitalizeFirstLetter } from "util/formatDateAndTime";
import { Grid } from "@mui/material";
import { Tooltip } from "@mui/material";
function Transactions({ recieveStatsFunc }) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [transactions, setTransactions] = useState([])
    const [totalTransaction, setTotaltransaction] = useState([])
    const [page, setPage] = useState(0)
    const [check, setCheck] = useState("all")
    const [currentStatus, setCurrentStatus] = useState("")
    const [reload, setReload] = useState(true)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [menu, setMenu] = useState(null);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);
    const [activeMerchant, setActiveMerchant] = useState('all')
    const [merchantInfo, setMerchantInfo] = useState([])
    const { columns, rows } = data(transactions, activeMerchant !== "all" ? merchantInfo.find(m => m.email_id === activeMerchant).business_name : "");
    const [csvDownloading, setCsvDownloading] = useState(false)
    let limit = 10;
    const formikRef = React.createRef();

    const handleResetForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };
    const initialSearchValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        transactionId: '',
    };
    const initialDateValues = {
        emailId: adminEmail || '',
        email_Id: activeMerchant,
        apiKey: adminApiKey || '',
        start_date: '',
        end_date: ''
    };
    const [formikValues, setFormikValues] = useState(initialDateValues);
    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={() => {

                setMenu(null);
                setCsvCompleteData([])
                setCheck("all")
                setCurrentStatus("")
                setPage(0)
                setReload(!reload)
                formikValues.start_date !== "" && formikValues.end_date !== "" && setFormikValues({ ...formikValues, email_Id: activeMerchant, start_date: "", end_date: "" })
                handleResetForm()

            }}>
                All
            </MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("success")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "success") : filterSearch("success")
                // handleResetForm()
            }}>Success</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("IN-PROCESS")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "pending") : filterSearch("pending")

                // handleResetForm()
            }}>Pending</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("failed")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "failed") : filterSearch("failed")
                // handleResetForm()
            }}>Failed</MenuItem>
            {/* <MenuItem onClick={() => {
                setCurrentStatus("expired")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "expired") : filterSearch("expired")
                // handleResetForm()
            }}>Expired</MenuItem> */}
        </Menu>
    );

    //on change of active merchant get stats of activeMerchant (DONE)
    const getActiveMerchantStats = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: activeMerchant
            }
            if (body.emailId === '' || body.apiKey === '' || body.email_Id === "all") {
                return;
            }

            const response = await fetch(`${apiUrl}/payouts/getPayoutDataMerchant`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            recieveStatsFunc(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    // With this we can find a single transaction by utr globally over all merchant (DONE)
    const handleSearch = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '' || values.transactionId === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/payouts/getPayoutDataById`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTransactions(res.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // with this we can get all the merchant info for dropdown (DONE)
    const getAllMerchant = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getallmerchantsinfo`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setMerchantInfo(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // with this we get date wise transaction when activeMerchant is all,this endpoint doesnt have pagination on backend (DONE)
    const handleDateSearch = async (values, status = "") => {
        try {
            if (values.start_date === "" || values.end_date === "" || values.apiKey === "") {
                return;
            }
            if (values.end_date < values.start_date || values.start_date > Date.now()) {
                toast.error('Invalid Date Range!')
                return;
            }
            if (activeMerchant !== "all") {
                // if there is any merchant then we will call anthor fetch function here that will do same 
                handleDateSearchByMerchant(values, status)
                return
            }
            if (status !== "") {
                values.status = status
            } else {
                values.status = "all"
            }
            // Delete the email_Id property from values
            const newValues = { ...values }
            delete newValues.email_Id;
            setCheck("date")
            setCsvCompleteData([])
            const response = await fetch(`${apiUrl}/payouts/getAllPayouts`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newValues)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                setTransactions([])
                return;
            }
            setTransactions(res.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // with this we get date wise transaction of any merchant,this endpoint doesnt have pagination on backend (DONE)
    const handleDateSearchByMerchant = async (values, status = "") => {
        try {
            if (values.start_date === "" || values.end_date === "" || values.apiKey === "") {
                return;
            }
            if (values.end_date < values.start_date || values.start_date > Date.now()) {
                toast.error('Invalid Date Range!')
                return;
            }
            if (status !== "") {
                values.status = status
            } else {
                values.status = "all"
            }
            // Delete the email_Id property from values
            const newValues = { ...values }
            setCheck("date")
            setCsvCompleteData([])
            const response = await fetch(`${apiUrl}/payouts/getAllPayoutsDataMerchants`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newValues)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                setTransactions([])
                return;
            }
            setTransactions(res.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentStatus === "" and activeMerchant === "all" for table (DONE)
    const getAllTransactions = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            limit,
            skip: limit * page
        }
        try {
            const response = await fetch(`${apiUrl}/payouts/getAllPayoutTransactions`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentStatus === "" and activeMerchant === "all" for csv(DONE)
    const getAllTransactionsForCsv = async (body) => {
        try {
            const response = await fetch(`${apiUrl}/payouts/getAllPayoutTransactions`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentstatus ==="" and for single merchant for table (finding all the transaction of single merchant) (DONE)
    const getSingleMerchantAllTransaction = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            email_Id: activeMerchant,
            limit,
            skip: limit * page
        }
        try {
            const response = await fetch(`${apiUrl}/payouts/getAllPayoutsMerchants`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentstatus ==="" and for single merchant for csv (finding all the transaction of single merchant) (DONE)
    const getSingleMerchantAllTransactionForCsv = async (body) => {
        try {
            const response = await fetch(`${apiUrl}/payouts/getAllPayoutsMerchants`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when we are finding transactions of any mercant by status (success, IN-PROCESS,failed) for table
    const filterSearch = async (status) => {
        setCurrentStatus(status)
        setCheck("status")
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            start_date: "all",
            end_date: "all",
            email_Id: activeMerchant,
            status,
            limit,
            skip: limit * page
        }
        if (body.emailId === '' || body.apiKey === '') {
            return;
        }
        if (body.email_Id === 'all') {
            let newBody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey,
                start_date: "all",
                end_date: "all",
                status,
                limit,
                skip: limit * page
            }
            try {
                const response = await fetch(`${apiUrl}/payouts/getAllPayouts`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newBody)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    setTransactions([])
                    return;
                }
                setTotaltransaction(res?.responseData)
                setTransactions(res?.responseData)
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        } else {
            try {
                const response = await fetch(`${apiUrl}/payouts/getAllPayoutsDataMerchants`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    setTransactions([])
                    return;
                }
                setTotaltransaction(res?.responseData)
                setTransactions(res?.responseData)
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        }

    }
    // when we are finding transactions of any mercant by status (success, IN-PROCESS,failed) for csv
    const filterSearchForCsv = async (body) => {
        if (body.email_Id === "all") {
            try {
                delete body.email_Id
                const response = await fetch(`${apiUrl}/payouts/getAllPayouts`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    // setTransactions([])
                    return;
                }
                return res.responseData
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        } else {
            try {
                const response = await fetch(`${apiUrl}/payouts/getAllPayoutsDataMerchants`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    // setTransactions([])
                    return;
                }
                return res.responseData
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        }

    }

    const generateCsv = async (type) => {
        setCsvDownloading(true)
        let csvPage = 0
        // getting all the transactions of all the merchant
        if (activeMerchant === "all" && currentStatus === "" && check === "all") {
            let responseData = true
            do {
                let body = {
                    emailId: adminEmail || '',
                    apiKey: adminApiKey || '',
                    limit: 5000,
                    skip: 5000 * csvPage
                }
                await getAllTransactionsForCsv(body).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        } // getting all the transaction of single merchant , if currentStatus === "" and active merchant has something then we have to find all the transaction of that merchant
        else if (currentStatus === "" && activeMerchant !== "all" && check !== "date") {
            let responseData = true
            do {
                let body = {
                    emailId: adminEmail || '',
                    apiKey: adminApiKey || '',
                    email_Id: activeMerchant,
                    limit: 5000,
                    skip: 5000 * csvPage
                }
                await getSingleMerchantAllTransactionForCsv(body).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        }
        else if (check === "status") {
            let responseData = true
            do {
                let body = {
                    emailId: adminEmail || '',
                    apiKey: adminApiKey || '',
                    start_date: "all",
                    end_date: "all",
                    email_Id: activeMerchant,
                    status: type,
                    limit: 5000,
                    skip: 5000 * csvPage
                }
                await filterSearchForCsv(body).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        }
        else if (check === "date") {
            csvCompleteData.push(...transactions)
        }
        else {
            toast.error("Something is wrong ! Please Select a merchant ")
            return
        }
        // Now that you have all the data in csvCompleteData, remove duplicates based on the _id field.
        const uniqueCsvCompleteData = csvCompleteData.filter((value, index, self) => {
            const firstIndex = self.findIndex((item) => item._id === value._id);
            return index === firstIndex;
        });
        // Set csvCompleteData to the unique data.
        setCsvCompleteData(uniqueCsvCompleteData)
        const csvData =
            [
                ["Time", "Date", "Merchant", "Gateway", "Amount", "status", "method", "AC.NO./UPI-ID", "AC.NAME", "IFSC", "BANK", "UTR", "TXID"],
                ...csvCompleteData.map(({ transaction_date, amount, business_name, gateway, status, method, upiId, account_number, account_name, ifsc_code, bank_name, utr, transactionId }) => [
                    formatDateAndTime(transaction_date).formattedTime || '',
                    formatDateAndTime(transaction_date).formattedDate || '',
                    business_name ? business_name : merchantInfo.find(m => m.email_id === activeMerchant)?.business_name,
                    gateway,
                    amount || '',
                    status ? status === "IN-PROCESS" ? "pending" : status === "fail" ? "failed" : status.toLowerCase() : '',
                    method ? capitalizeFirstLetter(method) : '',
                    method === "upi" ? upiId ? upiId : "" : account_number ? hideDigits(account_number) : '',
                    account_name || '',
                    method === "upi" ? "N/A" : ifsc_code || '',
                    method === "upi" ? "N/A" : bank_name || '',
                    utr || '',
                    transactionId || ''
                ]),
            ].map(row => row.join(',')).join('\n');
        // Create a Blob containing the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "Payouts.csv"

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setCsvDownloading(false)
    }


    useEffect(() => {
        handleResetForm()
        if (activeMerchant !== "all") {
            getActiveMerchantStats()
        }
        else if (activeMerchant === "all") {
            recieveStatsFunc("all")
        }
    }, [activeMerchant, sandbox])


    useEffect(() => {
        if (activeMerchant !== 'all' && currentStatus === "") {
            getSingleMerchantAllTransaction()
        }
        else if (activeMerchant === 'all' && currentStatus === "") {
            getAllTransactions()
        }
        else if (currentStatus !== "all") {
            filterSearch(currentStatus)
        }
    }, [activeMerchant, page, reload, sandbox])

    useEffect(() => {
        if (merchantInfo.length === 0) {
            getAllMerchant()
        }
        if (check === "all" && activeMerchant === "all") {
            getAllTransactions()
        }
        else if (check === "status") {
            filterSearch(currentStatus)
        }
    }, [page, reload, sandbox])

    return (
        <Card>
            <MDBox display={'flex'} flexDirection={'column'}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
                    <Grid container alignItems="center" p={1}>
                        <Grid item xl={2}>
                            <MDBox ml={1} alignItems="center">
                                <MDTypography variant="h6" gutterBottom>
                                    {check === "all" ? 'ALL ' : check === "date" ? 'DATE WISE ' : currentStatus.toUpperCase()} PAYOUTS
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xl={10}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xl={4} sm={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xl={6} xs={6} sm={6}>
                                            {/* selectMerchant */}
                                            <Formik
                                                initialValues={{ active_merchant: '' }}
                                            >
                                                {({ setFieldValue }) => (
                                                    <Form>
                                                        <MDBox mb={0.5} >
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Select Merchant
                                                            </MDTypography>
                                                            <Field
                                                                type="text"
                                                                select
                                                                size="large"
                                                                as={MDInput}
                                                                fullWidth
                                                                value={activeMerchant}
                                                                onChange={(e) => {
                                                                    e.target.value !== "" && setActiveMerchant(e.target.value); setPage(0); setCurrentStatus(''); setCheck('all'); setCsvCompleteData([]);
                                                                    setFormikValues({ ...formikValues, email_Id: activeMerchant, start_date: "", end_date: "" })

                                                                }}
                                                                name="active_merchant"
                                                                InputProps={{
                                                                    classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                }}
                                                            >
                                                                <MenuItem value="">Select Merchant</MenuItem>
                                                                <MenuItem value="all">All</MenuItem>
                                                                {merchantInfo && merchantInfo.map((merchant, index) => (
                                                                    <MenuItem key={index} value={merchant.email_id}>
                                                                        {merchant.business_name?.toUpperCase()}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </MDBox>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                        <Grid item xl={6} xs={6} sm={6}>
                                            {/* search input */}
                                            <Formik
                                                initialValues={initialSearchValues}
                                                onSubmit={handleSearch}
                                            >
                                                {({ setFieldValue }) => (
                                                    <Form>
                                                        <MDBox mb={0.5}>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Search by UTR or TXID
                                                            </MDTypography>
                                                            <Field
                                                                onChange={(e) => {
                                                                    setFieldValue('transactionId', e.target.value)
                                                                    if (initialSearchValues.transactionId === '') {
                                                                        setTransactions(totalTransaction)
                                                                    }
                                                                }}
                                                                type="text" as={MDInput} name="transactionId" fullWidth
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton type="submit" edge="end">
                                                                                <SearchIcon color={darkMode ? "white" : "black"} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}

                                                            />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="transactionId" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={4} sm={12} xs={12}>
                                    {/* date input */}
                                    <Formik
                                        initialValues={initialDateValues}
                                        innerRef={formikRef}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>
                                                <Grid container spacing={1}>
                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <MDBox>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Start date
                                                            </MDTypography>
                                                            <Field
                                                                size="large"
                                                                onChange={async (e) => {
                                                                    await setFieldValue('start_date', e.target.value)
                                                                    const newFormikValues = { ...formikValues, start_date: e.target.value, email_Id: activeMerchant, };
                                                                    setFormikValues(newFormikValues);
                                                                    setPage(0)
                                                                    handleDateSearch(newFormikValues);
                                                                }}
                                                                type="date" as={MDInput} fullWidth name="start_date"
                                                            />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="start_date" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <MDBox>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                End date
                                                            </MDTypography>
                                                            <Field
                                                                size="large"
                                                                onChange={async (e) => {
                                                                    await setFieldValue('end_date', e.target.value)
                                                                    const newFormikValues = { ...formikValues, end_date: e.target.value, email_Id: activeMerchant, };
                                                                    setFormikValues(newFormikValues);
                                                                    setPage(0)
                                                                    handleDateSearch(newFormikValues);
                                                                }}
                                                                type="date" as={MDInput} fullWidth name="end_date" />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="end_date" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                                <Grid item xl={4} sm={12} xs={12} mt={4}>
                                    <Grid container spacing={1}>
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                            <MDBox>
                                                <MDButton
                                                    variant="contained"
                                                    startIcon={<TuneOutlinedIcon />}
                                                    onClick={openMenu}
                                                    color={"success"}
                                                    fullWidth
                                                >
                                                    Filter
                                                </MDButton>
                                                {renderMenu}
                                            </MDBox>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                            <MDBox>
                                                <Tooltip
                                                    title={csvDownloading === true ? "Download report is in progress" : ""}
                                                    placement="top"
                                                >
                                                    <span>
                                                        {/* Wrapping the button in a <span> ensures proper Tooltip behavior for disabled buttons */}
                                                        <MDButton
                                                            variant="contained"
                                                            color="info"
                                                            startIcon={<FileDownloadOutlinedIcon />}
                                                            onClick={() =>
                                                                generateCsv(
                                                                    check === "all" ? check : check === "date" ? "date" : currentStatus
                                                                )
                                                            }
                                                            fullWidth
                                                            disabled={csvDownloading} // Disable the button if isGenerateButton is false
                                                        >
                                                            Download
                                                        </MDButton>
                                                    </span>
                                                </Tooltip>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox>
                    <DataTable
                        table={{ columns, rows }}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                        entriesPerPage={false}
                        pages={page}
                        pageSetter={setPage}
                        checking={check}
                    />
                </MDBox>
            </MDBox>
        </Card>

    );
}

Transactions.propTypes = {
    recieveStatsFunc: PropTypes.func
}
export default Transactions;

