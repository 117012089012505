import { create } from 'zustand'

export const useSnackBar = create((set) => ({
    successSB: false,
    warningSB: false,
    openSuccessSB: (value) =>
        set((state) => ({ successSB: true })),
    closeSuccessSB: () =>
        set((state) => ({ successSB: false })),
    openWarningSB: (value) =>
        set((state) => ({ warningSB: true })),
    closeWarningSB: () =>
        set((state) => ({ warningSB: false }))
}))