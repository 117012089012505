
// @mui material components
import MDTypography from "components/MDTypography";
import formatDateAndTime from "util/formatDateAndTime";
import { useMaterialUIController } from "context";
export default function data(logs) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "No.", accessor: "no", width: '4%', align: "left" },
            { Header: "Time", accessor: "time", width: '8%', align: "left" },
            { Header: "Date", accessor: "date", width: '8%', align: "left" },
            { Header: "Gross Volume", accessor: "grossVolume", width: '10%', align: "right" },
            { Header: "Net Platform Fee", accessor: "netfees", width: '10%', align: "right" },
            { Header: "Net Gst", accessor: "netgst", width: '10%', align: "right" },
            { Header: "Net Volume", accessor: "netVolume", width: '15%', align: "right" },
            { Header: "Remarks", accessor: "ref", width: '15%', align: "left" },
        ],

        rows: logs && logs.map((row, index) => ({
            no: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.index + 1}
                </MDTypography>
            ),
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(row?.transaction_date).formattedTime || ''}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(row?.transaction_date).formattedDate || ''}
                </MDTypography>
            ),
            grossVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.amount ? row?.amount?.toFixed(0) : ''}
                </MDTypography>
            ),
            netfees: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.netPlatformFee ? row?.netPlatformFee?.toFixed(2) : 0}
                </MDTypography>
            ),
            netgst: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.netGst ? row?.netGst?.toFixed(2) : 0}
                </MDTypography>
            ),
            netVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.netVolume ? row?.netVolume?.toFixed(2) : 0}
                </MDTypography>
            ),
            ref: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.ref}
                </MDTypography>
            ),
        }))
    }
}
