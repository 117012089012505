import React, { useState, useEffect } from "react";
import { Modal, IconButton, Grid, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useTheme } from "@mui/material/styles";
import { useMaterialUIController } from "context";
const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: 500,
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

const CalculatorModal = ({ open, handleClose }) => {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const theme = useTheme();  // Access the current theme
    const [values, setValues] = useState({
        grossVolume: "",
        platformFees: "",
        bankingFees: "",
        gst: "",
    });
    const [result, setResult] = useState(null);

    // Reset values when modal is closed
    useEffect(() => {
        if (!open) {
            setValues({
                grossVolume: "",
                platformFees: "",
                bankingFees: "",
                gst: "",
            });
            setResult(null);
        }
    }, [open]);

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const calculateResult = (e) => {
        e.preventDefault();
        const grossVolume = parseFloat(values.grossVolume) || 0;
        const platformFees = parseFloat(values.platformFees) || 0;
        const bankingFees = parseFloat(values.bankingFees) || 0;
        const gst = parseFloat(values.gst) || 0;
        let netVolume = 0;
        const platformFeeAmount = (platformFees / 100) * grossVolume;
        netVolume = grossVolume - platformFeeAmount
        const bankingFeeAmount = (bankingFees / 100) * netVolume;
        netVolume = netVolume - bankingFeeAmount
        const gstAmount = (gst / 100) * platformFeeAmount;
        netVolume = netVolume - gstAmount

        setResult(netVolume.toFixed(2));
    };

    const resetCalculator = () => {
        setValues({
            grossVolume: "",
            platformFees: "",
            bankingFees: "",
            gst: "",
        });
        setResult(null);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <MDBox
                sx={{
                    ...modalStyle,
                    bgcolor: theme.palette.background.default, // Set background color based on the theme
                }}
            >
                {/* Header */}
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <MDTypography variant="h5" fontWeight="bold">
                        Settlement Calculator
                    </MDTypography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="medium" color={darkMode ? "white" : "black"} />
                    </IconButton>
                </MDBox>

                {/* Result Display */}
                <MDBox
                    height="80px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid #ddd"
                    borderRadius="8px"
                    bgcolor="background.default"
                    mb={3}
                >
                    <MDTypography variant="h6" color={darkMode ? "white" : "secondary"}>
                        {result ? `Net Volume: ₹${result}` : "Result will appear here"}
                    </MDTypography>
                </MDBox>

                {/* Form Inputs */}
                <form onSubmit={calculateResult}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Gross Volume"
                                name="grossVolume"
                                type="number"
                                value={values.grossVolume}
                                onChange={handleChange}
                                variant="outlined"
                                InputProps={{
                                    inputProps: { step: "any" },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Platform Fees (%)"
                                name="platformFees"
                                type="number"
                                value={values.platformFees}
                                onChange={handleChange}
                                variant="outlined"
                                InputProps={{
                                    inputProps: { step: "any" },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Banking Fees (%)"
                                name="bankingFees"
                                type="number"
                                value={values.bankingFees}
                                onChange={handleChange}
                                variant="outlined"
                                InputProps={{
                                    inputProps: { step: "any" },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="GST (%)"
                                name="gst"
                                type="number"
                                value={values.gst}
                                onChange={handleChange}
                                variant="outlined"
                                InputProps={{
                                    inputProps: { step: "any" },
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Buttons */}
                    <MDBox mt={4} display="flex" justifyContent="space-between">
                        <MDButton
                            color="info"
                            variant="outlined"
                            onClick={resetCalculator}
                            fullWidth
                            sx={{ marginRight: "8px" }}
                        >
                            Reset
                        </MDButton>
                        <MDButton color="success" variant="contained" type="submit" fullWidth>
                            Calculate
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        </Modal>
    );
};

CalculatorModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CalculatorModal;
