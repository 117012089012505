import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
// import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import "./csvReportList.css";
import { useConfig } from "../../config";
import { capitalizeFirstLetter } from "util/formatDateAndTime";
// Function to calculate human-readable time difference
const formatTime = (date) => {
    const now = new Date();
    const generatedTime = new Date(date);
    const diffInMs = now - generatedTime;

    const diffInSeconds = Math.floor(diffInMs / 1000);
    if (diffInSeconds < 60) return "Just now";

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} mins ago`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hours ago`;

    return generatedTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
    });
};


const CsvReportList = () => {
    const { apiUrl } = useConfig()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [csvReports, setCsvReports] = useState([]);

    const getAllCSVReports = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            limit: 10,
            skip: 0
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getCsvUrl`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setCsvReports(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAllCSVReports: ", err)
        }
    }
    useEffect(() => {
        getAllCSVReports()
    }, [])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox className="csv-report-table-container">
                <header className="table-header">
                    <h2>Generated CSV Reports</h2>
                </header>
                <MDBox className="csv-table-wrapper">
                    <table className="csv-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Generated Time</th>
                                <th>CSV Status</th>
                                <th>Merchant Name</th>
                                <th>Transaction Type</th>
                                <th>Transaction Status</th>
                                <th>Date Range</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {csvReports && csvReports.map((report, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{formatTime(report?.creation_date)}</td>
                                    <td>
                                        <span
                                            className={`badge ${report?.csv_status === "completed"
                                                ? "badge-completed"
                                                : "badge-progress"
                                                }`}
                                        >
                                            {report?.csv_status === "completed" ? (
                                                <CheckCircleIcon fontSize="small" />
                                            ) : (
                                                <HourglassBottomIcon fontSize="small" />
                                            )}
                                            {capitalizeFirstLetter(report?.csv_status)}
                                        </span>
                                    </td>
                                    <td>{capitalizeFirstLetter(report?.merchant_name)}</td>
                                    <td>{capitalizeFirstLetter(report?.transaction_type)}</td>
                                    <td>{report?.status === "" ? "---" : report?.status === "IN-PROCESS" ? "Pending" : capitalizeFirstLetter(report?.status)}</td>
                                    <td>
                                        {report?.start_date && report?.end_date ? (
                                            `${report.start_date} to ${report.end_date}`
                                        ) : (
                                            "---"
                                        )}
                                    </td>
                                    <td>
                                        <div className="actions">
                                            {report?.csv_url !== "" && (
                                                <Tooltip title="Download CSV">
                                                    <IconButton
                                                        size="medium"
                                                        className="icon-button"
                                                        style={{ color: "#28a745" }}
                                                        onClick={() => window.open(report?.csv_url,)}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {/* <Tooltip title="Delete Report">
                                                <IconButton
                                                    size="small"
                                                    className="icon-button"
                                                    style={{ color: "#dc3545" }}
                                                    onClick={() => alert(`Delete report #${report.id} clicked`)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
};

export default CsvReportList;
