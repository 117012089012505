/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Badge from "@mui/material/Badge";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Card, Grid } from "@mui/material";
import { MenuItem } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import MDInput from "components/MDInput";
import { useConfig } from "../../../../config";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Switch from "@mui/material/Switch";
import { useSandbox } from "ZustandState/useSanbox";
import { capitalizeFirstLetter } from "util/formatDateAndTime";
// const useStyles = makeStyles((theme) => ({
//     customBadge: {
//         backgroundColor: "gold",
//         color: "black",
//         fontWeight: "bold",
//         padding: "6px 10px",
//         borderRadius: "10px",
//         fontSize: "14px",
//         marginLeft: "-5px", // Adjust this value to position the badge as desired
//     },
// }));
function Merchant({ first_name, last_name, business, email, balance, payinLimit, payoutLimit, premium, gateway, payoutGateway, platformFee, noGutter, func, isBanned, isActive, }) {
    const { apiUrl } = useConfig()
    const { sandbox } = useSandbox()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [payinGateways, setPayinGateways] = useState([]);
    const [payoutGateways, setPayoutGateways] = useState([])
    const [Baned, setBanned] = useState(isBanned);
    const [payoutActivate, setPayoutActivate] = useState(isActive);
    // const [isSubmitting, setSubmitting] = useState(false)
    // const user = useUser((state) => state.user)
    const initialGatewayValue = {
        emailId: adminEmail || '',  // admin emailId from state
        apiKey: adminApiKey || '',  // admin apiKey from state 
        email_Id: email || '',  // merchant email from transaction data
        gateway: gateway || '',  // merchant default gateway from transaction data 
    }
    const initialPayoutGatewayValue = {
        emailId: adminEmail || '',  //   emailId from state
        apiKey: adminApiKey || '',  // admin apiKey from state 
        email_Id: email || '',  // merchant email from transaction data
        gateway: payoutGateway || '',  // merchant default gateway from transaction data 
    }
    const initialPlatformFeeValue = {
        emailId: adminEmail || '',  // admin emailId from state
        apiKey: adminApiKey || '',  // admin apiKey from state 
        email_Id: email || '',  // merchant email from transaction data
        platformFee: platformFee,  // merchant default gateway from transaction data 
    }
    const validationSchema = Yup.object({
        platformFee: Yup.number().max(100).required('Gateway Fee is required'),
    });
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const handleUpdateGateway = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/updategateway`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let adminbody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            }
            func(adminbody)
            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        } catch (error) {
            //Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            // Reset submitting state
            setSubmitting(false);
        }
    }
    const handleUpdatePayoutGateway = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/updatepayoutgateway`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let adminbody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            }
            func(adminbody)
            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
    } catch (error) {
      //Display an error toast
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      // Reset submitting state
      setSubmitting(false);
    }
  };
  const handleUpdatePlatformFee = async (values, { setSubmitting }) => {
    try {
      if (values.emailId === "" || values.apiKey === "") {
        toast.error("Invalid credential! Please Sign in again..", {
          position: "top-right",
          autoClose: 2000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
        });
        localStorage.removeItem("admin_token");

        // Navigate to the sign-in page
        navigate("/authentication/sign-in");
        return;
      }
      const response = await fetch(`${apiUrl}/admin/updateusergatewayfee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const res = await response.json();
      if (res.responseCode !== 200) {
        toast.error(res.responseMessage, {
          position: "top-right",
          autoClose: 2000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
        });
        return;
      }
      let adminbody = {
        emailId: adminEmail || "",
        apiKey: adminApiKey || "",
      };
      func(adminbody);
      setSubmitting(false);
      toast.success(res.responseMessage, {
        position: "top-right",
        autoClose: 2000, // Auto close the toast after 3 seconds
        hideProgressBar: false,
      });
    } catch (error) {
      //Display an error toast
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      // Reset submitting state
      setSubmitting(false);
    }
  };
  const handleUpdateBanStatus = async (body) => {
    try {
      setBanned((prevIsBanned) => !prevIsBanned);
      if (body.emailId === "" || body.apiKey === "") {
        toast.error("Invalid credential! Please Sign in again..", {
          position: "top-right",
          autoClose: 2000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
        });
        localStorage.removeItem("admin_token");

        // Navigate to the sign-in page
        navigate("/authentication/sign-in");
        return;
      }
      const response = await fetch(`${apiUrl}/admin/banmerchant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const res = await response.json();
      if (res.responseCode !== 200) {
        setBanned((prevIsBanned) => !prevIsBanned);
        toast.error(res.responseMessage, {
          position: "top-right",
          autoClose: 2000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
        });
        return;
      }
      let adminbody = {
        emailId: adminEmail || "",
        apiKey: adminApiKey || "",
      };
      func(adminbody);
      toast.success(res.responseMessage, {
        position: "top-right",
        autoClose: 2000, // Auto close the toast after 3 seconds
        hideProgressBar: false,
      });
    } catch (error) {
      //Display an error toast
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };
  const handleUpdatePayoutStatus = async (body) => {
    try {
      setPayoutActivate((prevIsActivate) => !prevIsActivate);
      if (body.emailId === "" || body.apiKey === "") {
        toast.error("Invalid credential! Please Sign in again..", {
          position: "top-right",
          autoClose: 2000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
        });
        localStorage.removeItem("admin_token");

        // Navigate to the sign-in page
        navigate("/authentication/sign-in");
        return;
      }
      const response = await fetch(`${apiUrl}/admin/activatePayout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const res = await response.json();
      if (res.responseCode !== 200) {
        setPayoutActivate((prevIsActivate) => !prevIsActivate);
        toast.error(res.responseMessage, {
          position: "top-right",
          autoClose: 2000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
        });
        return;
      }
      let adminbody = {
        emailId: adminEmail || "",
        apiKey: adminApiKey || "",
      };
      func(adminbody);
      toast.success(res.responseMessage, {
        position: "top-right",
        autoClose: 2000, // Auto close the toast after 3 seconds
        hideProgressBar: false,
      });
    } catch (error) {
      //Display an error toast
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };
  const getAllPayinGateways = async () => {
    let body = {
      emailId: adminEmail || "",
      apiKey: adminApiKey || "",
    };
    try {
      const response = await fetch(`${apiUrl}/admin/getallgateways`, {
        method: "POST",
        headers: {
          //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const res = await response.json();
      if (res.responseCode !== 200) return;
      setPayinGateways(res?.responseData);
    } catch (err) {
      console.log("Error Fetching getAllPayinGateways: ", err);
    }
  };
  const getAllPayoutGateways = async () => {
    let body = {
      emailId: adminEmail || "",
      apiKey: adminApiKey || "",
    };
    try {
      const response = await fetch(`${apiUrl}/admin/getAllPayoutGateways`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const res = await response.json();
      if (res.responseCode !== 200) return;
      setPayoutGateways(res?.responseData);
    } catch (err) {
      console.log("Error Fetching getAllPayoutGateways: ", err);
    }
  };
  useEffect(() => {
    getAllPayinGateways();
    getAllPayoutGateways();
  }, []);

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
      overflow="hidden"
    >
      <MDBox width="100%" display="flex" flexDirection="column">
       <Card sx={{ paddingY: 1 }}>
         <MDTypography
          variant="h6"
          fontWeight="medium"
          textAlign="center"
          textTransform="uppercase"
          >
          {business || ""}
         </MDTypography>
       </Card>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", md: "row" }}
          mb={2}
          width="100%"
        >
          <MDBox display="flex" gap={1}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <MDTypography variant="button" fontWeight="medium">
                PAYIN(OFF/ON)
              </MDTypography>

              <Switch
                checked={!Baned}
                onChange={() => {
                  handleUpdateBanStatus({
                    emailId: adminEmail || "",
                    apiKey: adminApiKey || "",
                    email_Id: email,
                    isBanned: isBanned === false ? true : false,
                  });
                }}
              />
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row", md: "row" },
              }}
            >
              <MDTypography variant="button" fontWeight="medium">
                PAYOUT(OFF/ON)
              </MDTypography>

              <Switch
                checked={payoutActivate}
                onChange={() => {
                  handleUpdatePayoutStatus({
                    emailId: adminEmail || "",
                    apiKey: adminApiKey || "",
                    email_id: email,
                    isActive: isActive === false ? true : false,
                  });
                }}
              />
            </MDBox>
          </MDBox>
          <MDBox container spacing={2}>
            <Grid item xs={12} sm={8} md={14} display="flex" overflow={"scroll"}>
              {!sandbox && (
                <MDButton variant="h2" style={{ textAlign: "center" }}>
                  <Link
                    to={"/update-limit"}
                    state={{ payinLimit, payoutLimit, business, email }}
                    style={{ color: darkMode ? "white" : "black", textDecoration: "none" }}
                  >
                    <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <MDBox paddingX={1} mt={0.5}><Icon fontSize="small">speed</Icon></MDBox>
                    <MDTypography variant="button" fontWeight="medium">UPDATE LIMIT</MDTypography>
                    </Grid>
                  </Link>
                </MDButton>
              )}
              <MDButton variant="h2" style={{ textAlign: "center" }}>
                <Link
                  to={"/edit-merchant"}
                  state={{ email, business, first_name, last_name }}
                  style={{ color: darkMode ? "white" : "black", textDecoration: "none" }}
                >
                  <Grid container alignItems="center" justifyContent="center" spacing={1}>
                  <MDBox paddingX={1} mt={0.5}><Icon fontSize="small">edit</Icon></MDBox>
                  <MDTypography variant="button" fontWeight="medium">EDIT MERCHANT</MDTypography>
                  </Grid>
                </Link>
              </MDButton>
              <MDButton variant="h2" style={{ textAlign: "center" }}>
                <Link
                  to={"/reset-password"}
                  state={{ email, business }}
                  style={{ color: darkMode ? "white" : "black", textDecoration: "none" }}
                >
                  <Grid container alignItems="center" justifyContent="center" spacing={1}>
                  <MDBox paddingX={1} mt={0.5}><Icon fontSize="small">lock_reset</Icon></MDBox>
                  <MDTypography variant="button" fontWeight="medium">RESET PASSWORD</MDTypography>
                  </Grid>
                </Link>
              </MDButton>
            </Grid>
          </MDBox>
          {/* <MDBox display="flex" alignItems="center"  flexDirection={{ xs: "column", sm: "row" }} mt={{ xs: 2, sm: 0 }} gap={{ xs: 2, sm: 4 }}> */}

          {/* <MDButton variant="text" color={isBanned ? "success" : "error"}>
                            <div
                                onClick={() =>
                                    handleUpdateBanStatus({
                                        emailId: adminEmail || '',
                                        apiKey: adminApiKey || '',
                                        email_Id: email,
                                        isBanned: isBanned === false ? true : false
                                    })
                                }
                            >
                                <Icon>
                                    {isBanned === false ? <Icon>not_interested</Icon> : <Icon>check</Icon>}
                                </Icon>
                                {isBanned === false ? 'Ban Merchant' : 'Unban Merchant'}
                            </div>
                        </MDButton> */}
          {/* </MDBox> */}
        </MDBox>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Card sx={{p: 2,height: "200px",width: {xs:'320px', sm: "320px",lg:"350px" },display: "flex",flexDirection: "column",justifyContent: "space-between",}}>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Name:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
                  {first_name ? `${first_name} ${last_name}` : "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Balance:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium">
                  {balance || "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Payin Limit:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium">
                {payinLimit || "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Payout Limit:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium">
                  {payoutLimit || "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Payin Gateway:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium">
                 {gateway || "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Payout Gateway:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium">
                {payoutGateway || "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Merchant Fee:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium">
                {platformFee ? `${platformFee}%` : "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="caption" color="text">Email Address:</MDTypography>
                <MDTypography variant="caption" fontWeight="medium">
                 {email || "-"}
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2} display={"flex"} flexDirection={"column"}>
              {/* select payin gateway */}
              <Grid item xs={12}>
                <MDBox>
                  <Formik initialValues={initialGatewayValue} onSubmit={handleUpdateGateway}>
                    {({ isSubmitting }) => (
                      <Form>
                        <Grid container spacing={2}>
                          {/* Dropdown */}
                          <Grid item xs={6}>
                            {/* Adjust the size as needed */}
                            <Field
                              type="text"
                              select
                              size="large"
                              label="Select Payin Gateway"
                              as={MDInput}
                              fullWidth
                              name="gateway"
                              InputProps={{
                                classes: {
                                  root: darkMode
                                    ? "select-input-styles-darkMode"
                                    : "select-input-styles",
                                },
                              }}
                            >
                              {payinGateways &&
                                payinGateways.map((item, index) => (
                                  <MenuItem
                                    textTransform="uppercase"
                                    key={index}
                                    value={item.gatewayName}
                                  >
                                    {item.gatewayName?.toUpperCase()}
                                  </MenuItem>
                                ))}
                            </Field>
                          </Grid>

                          {/* Button */}
                          <Grid item xs={6}>
                            {" "}
                            {/* Adjust the size as needed */}
                            <MDButton
                              variant="gradient"
                              color="info"
                              type="submit"
                              disabled={isSubmitting}
                              fullWidth
                              white-space="nowrap"
                              overflow="hidden"
                              text-overflow="ellipsis"
                            >
                              {isSubmitting ? "Updating..." : "Update Payin Gateway"}
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
              {/* select payout gateway */}
              <Grid item xs={12}>
                <MDBox>
                  <Formik
                    initialValues={initialPayoutGatewayValue}
                    onSubmit={handleUpdatePayoutGateway}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Grid container spacing={2} display={"flex"} flexDirection={"row"}>
                          {/* Dropdown */}
                          <Grid item xs={6}>
                            {/* Adjust the size as needed */}
                            <Field
                              type="text"
                              select
                              size="large"
                              label="Select Payout Gateway"
                              as={MDInput}
                              fullWidth
                              name="gateway"
                              InputProps={{
                                classes: {
                                  root: darkMode
                                    ? "select-input-styles-darkMode"
                                    : "select-input-styles",
                                },
                              }}
                            >
                              {payoutGateways &&
                                payoutGateways.map((item, index) => (
                                  <MenuItem
                                    textTransform="uppercase"
                                    key={index}
                                    value={item.gatewayName}
                                  >
                                    {item.gatewayName?.toUpperCase()}
                                  </MenuItem>
                                ))}
                            </Field>
                          </Grid>

                          {/* Button */}
                          <Grid item xs={6}>
                            {/* Adjust the size as needed */}
                            <MDButton
                              variant="gradient"
                              color="info"
                              type="submit"
                              disabled={isSubmitting}
                              fullWidth
                            >
                              {isSubmitting ? "Updating..." : "Update Payout Gateway"}
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
              {/* Platform fee */}
              <Grid item xs={12}>
                <MDBox>
                  <Formik
                    initialValues={initialPlatformFeeValue}
                    validationSchema={validationSchema}
                    onSubmit={handleUpdatePlatformFee}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          alignItems={"center"}
                          display={"flex"}
                          flexDirection={"row"}
                        >
                          <Grid item xs={6} lg={6}>
                            {/* Adjust the size as needed */}
                            <MDTypography color="error" variant="caption">
                              <ErrorMessage name="platformFee" component="div" />
                            </MDTypography>
                            <Field
                              type="text"
                              label="Platform Fee"
                              as={MDInput}
                              fullWidth
                              name="platformFee"
                            ></Field>
                          </Grid>

                          {/* Button */}
                          <Grid item xs={6} lg={6}>
                            {" "}
                            {/* Adjust the size as needed */}
                            <MDButton
                              variant="gradient"
                              color="info"
                              type="submit"
                              disabled={isSubmitting}
                              fullWidth
                            >
                              {isSubmitting ? "Updating..." : "Update Fee"}
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <MDTypography mb={1} variant="caption" color="text">
                    Total Transactions:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium">
                        {transactions}
                    </MDTypography>
                </MDTypography> */}
        {/* <MDTypography mb={1} variant="caption" color="text">
                    Total Settlements:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium">
                        {settlements}
                    </MDTypography>
                </MDTypography> */}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Merchant
Merchant.defaultProps = {
  premium: false,
  noGutter: false,
};

// Typechecking props for the Merchant
Merchant.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  business: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  payinLimit: PropTypes.number.isRequired,
  payoutLimit: PropTypes.number.isRequired,
  premium: PropTypes.bool.isRequired,
  gateway: PropTypes.string.isRequired,
  payoutGateway: PropTypes.string.isRequired,
  platformFee: PropTypes.number.isRequired,
  isBanned: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  // transactions: PropTypes.number.isRequired,
  // settlements: PropTypes.number.isRequired,
  noGutter: PropTypes.bool,
  func: PropTypes.func,
};

export default Merchant;

